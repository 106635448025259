// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-index-template-js": () => import("./../src/templates/index-template.js" /* webpackChunkName: "component---src-templates-index-template-js" */),
  "component---src-templates-content-mapping-template-js": () => import("./../src/templates/content-mapping-template.js" /* webpackChunkName: "component---src-templates-content-mapping-template-js" */),
  "component---src-templates-404-template-js": () => import("./../src/templates/404-template.js" /* webpackChunkName: "component---src-templates-404-template-js" */),
  "component---src-templates-terms-of-use-template-js": () => import("./../src/templates/terms-of-use-template.js" /* webpackChunkName: "component---src-templates-terms-of-use-template-js" */),
  "component---src-templates-privacy-policy-template-js": () => import("./../src/templates/privacy-policy-template.js" /* webpackChunkName: "component---src-templates-privacy-policy-template-js" */),
  "component---src-templates-qa-template-js": () => import("./../src/templates/qa-template.js" /* webpackChunkName: "component---src-templates-qa-template-js" */),
  "component---src-templates-answer-template-js": () => import("./../src/templates/answer-template.js" /* webpackChunkName: "component---src-templates-answer-template-js" */),
  "component---src-templates-reset-password-template-js": () => import("./../src/templates/reset-password-template.js" /* webpackChunkName: "component---src-templates-reset-password-template-js" */),
  "component---src-templates-community-guidelines-template-js": () => import("./../src/templates/community-guidelines-template.js" /* webpackChunkName: "component---src-templates-community-guidelines-template-js" */)
}

